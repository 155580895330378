<template>
  <div>
    <a-card title="Logo配置" :loading="queryLoading">
      <a-row>
        <a-col :span="24">
          <a-form-item label="Logo标题" :label-col="{ span: 2 }" :wrapper-col="{ span: 6 }">
            <a-input v-model="logoItem.logo_title" />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="Logo图片" :label-col="{ span: 2 }" :wrapper-col="{ span: 6 }">
            <LogoImageUpload v-model="logoItem.logo_image" :initialItem="logoItem.logo_image_item" />
          </a-form-item>
        </a-col>
      </a-row>

      <div>
        <a-popconfirm title="确定保存吗?" :loading="confirmLoading" @confirm="create">
          <a-button type="primary" :loading="confirmLoading">保存</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import { logoConfigsList, logoConfigsSave } from "@/api/system";

export default {
  components: {
    LogoImageUpload: () => import("@/components/LogoImageUpload/"),
  },
  data() {
    return {
      logoItem: {},

      queryLoading: false,
      confirmLoading: false,
    };
  },
  methods: {
    list() {
      this.queryLoading = true;
      logoConfigsList()
        .then((data) => {
          this.logoItem = data;
        })
        .finally(() => {
          this.queryLoading = false;
        });
    },
    create() {
      this.confirmLoading = true;
      logoConfigsSave(this.logoItem)
        .then(() => {
          this.$message.success("保存成功");
          this.$router.go(0);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
